<template>
  <div class="d-flex">
    <div class="me-3">
      <select v-model="dob_" class="form-control">
        <option value="">-- Ngày --</option>
        <option v-for="i in dobs" :key="'dob' + i" :value="i">{{ i }}</option>
      </select>
    </div>
    <div class="me-3">
      <select v-model="mob_" class="form-control">
        <option value="">-- Tháng --</option>
        <option v-for="i in mobs" :key="'mob' + i" :value="i">{{ i }}</option>
      </select>
    </div>
    <div>
      <select v-model="yob_" class="form-control">
        <option value="">-- Năm --</option>
        <option v-for="i in yobs" :key="'yob' + i" :value="i">{{ i }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "Birthday",
  props: {
    dob: Number,
    mob: Number,
    yob: Number
  },
  data() {
    return {
      dob_: "",
      mob_: "",
      yob_: "",
      dobs: [],
      mobs: [],
      yobs: [],
    }
  },
  methods: {
    init() {
      for (let i = 1; i <= 31; i++) {
        this.dobs.push(i);
      }

      for (let i = 1; i <= 12; i++) {
        this.mobs.push(i);
      }

      let year = new Date().getFullYear();

      for (let i = year; i >= 1920; i--) {
        this.yobs.push(i);
      }
    }
  },
  mounted() {
    this.init();
  },
  created() {
    this.dob_ = this.dob;
    this.mob_ = this.mob;
    this.yob_ = this.yob;
  },
  watch: {
    dob_: {
      handler(newVal) {
        this.$emit("update:dob", newVal);
      }
    },
    dob: {
      handler(newVal) {
        this.dob_ = newVal;
      }
    },
    mob_: {
      handler(newVal) {
        this.$emit("update:mob", newVal);
      }
    },
    mob: {
      handler(newVal) {
        this.mob_ = newVal;
      }
    },
    yob_: {
      handler(newVal) {
        this.$emit("update:yob", newVal);
      }
    },
    yob: {
      handler(newVal) {
        this.yob_ = newVal;
      }
    },
  }
}
</script>