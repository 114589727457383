<template>
  <div class="py-6" style="background: linear-gradient(270deg, #9D4EFF 0%, #782AF4 100%);">
  </div>
  <!-- Page header -->
  <div class="bg-white shadow-sm">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
          <div class="d-md-flex align-items-center justify-content-between bg-white  pt-3 pb-3 pb-lg-5">
            <div class="d-md-flex align-items-center text-lg-start text-center ">
              <div class="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
                <a v-if="isMe" href="#" data-bs-toggle="modal" data-bs-target="#updateAvatarModal">
                  <img :src="me.thumbnail ? me.thumbnail.link : '/assets/images/avatar/avatar.jpg'" :alt="me.name"
                       class="avatar-xxl rounded-circle border border-4 border-white object-fit-cover">
                </a>
                <img v-if="!isMe" :src="me.thumbnail ? me.thumbnail.link : '/assets/images/avatar/avatar.jpg'" :alt="me.name"
                     class="avatar-xxl rounded-circle border border-4 border-white object-fit-cover">
              </div>
              <div class="mt-3 mt-md-0">
                <h1 class="mb-0 fw-bold me-3 ">{{ me.name }}</h1>
                <div>
                  <span class="ms-2 fs-6"><span class="text-dark fw-medium">{{
                      me.submits.paginatorInfo.total
                    }}</span> bài nộp</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Nav tab -->
          <ul class="nav nav-lt-tab" id="pills-tab" role="tablist">
            <li class="nav-item ms-0" role="presentation">
              <a v-on:click="setTab('course')" :class="tab === 'course' ? 'nav-link active' : 'nav-link'"
                 id="pills-course-tab" data-bs-toggle="pill" href="#pills-course" role="tab"
                 aria-controls="pills-course" aria-selected="true">Khóa học</a>
            </li>
            <li class="nav-item ms-0" role="presentation">
              <a v-on:click="setTab('submit')" :class="tab === 'submit' ? 'nav-link active' : 'nav-link'"
                 id="pills-submit-tab" data-bs-toggle="pill" href="#pills-submit" role="tab"
                 aria-controls="pills-submit" aria-selected="true">Bài nộp</a>
            </li>
            <li class="nav-item" role="presentation">
              <a v-on:click="setTab('info')" :class="tab === 'info' ? 'nav-link active' : 'nav-link'"
                 id="pills-info-tab" data-bs-toggle="pill" href="#pills-info" role="tab"
                 aria-controls="pills-info" aria-selected="false">Thông tin</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Content  -->
  <div class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="tab-content" id="pills-tabContent">
            <div :class="tab === 'info' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-info"
                 role="tabpanel" aria-labelledby="pills-info-tab">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h3 class="mb-0">Thông tin cá nhân</h3>
                  </div>
                  <div class="card-body">
                    <p>Họ tên: {{ me.name }}</p>
                    <p>Email: {{ me.email }}</p>
                    <p>SĐT: {{ me.phone }}</p>
                    <p>Sinh nhật: {{ me.dob ? me.dob : '--' }}/{{ me.mob ? me.mob : '--' }}/{{ me.yob ? me.yob : '--' }}</p>
                    <p>
                      Giới tính:
                      <span v-if="me.sex === 0">Nữ</span>
                      <span v-if="me.sex === 1">Nam</span>
                    </p>
                    <p>Mã tài khoản: {{ me.uid }}</p>
                    <button v-if="isMe" class="btn btn-primary" type="button" data-bs-target="#updateMeModal" data-bs-toggle="modal">Thay đổi thông tin cá nhân</button>
                    <button class="btn btn-primary d-none" type="button" id="updateMeModalOpen" data-bs-target="#updateMeModal" data-bs-toggle="modal">Thay đổi thông tin cá nhân</button>
                  </div>
                </div>

                <div class="card mt-4">
                  <div class="card-header">
                    <h3 class="mb-0">Mật khẩu</h3>
                  </div>
                  <div class="card-body">
                    <button class="btn btn-primary" data-bs-target="#changePasswordModal" data-bs-toggle="modal">Đổi mật khẩu</button>
                  </div>
                </div>
              </div>
            </div>
            <div :class="tab === 'course' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-course"
                 role="tabpanel" aria-labelledby="pills-course-tab">
              <div class="col-md-12">
                <div class="card pb-4">
                  <div class="card-header border-bottom-0">
                    <form class="d-flex align-items-center">
                      <input type="search" v-model="courseFilter.name" class="form-control me-4"
                             placeholder="Tên khóa học">
                      <select class="form-control me-3" v-model="courseFilter['user_course.percent']">
                        <option value="">-- Hoàn thành --</option>
                        <option value="100">Đã hoàn thành</option>
                        <option value="0">Chưa hoàn thành</option>
                      </select>
                      <select class="form-control" v-model="courseFilter.fee_type">
                        <option value="">-- Học phí --</option>
                        <option value=0>Miễn phí</option>
                        <option value=1>Trả phí</option>
                        <option value=2>Combo</option>
                      </select>
                    </form>
                  </div>
                  <table class="table mb-4 text-nowrap list">
                    <thead class="table-light">
                    <tr>
                      <th scope="col">Khóa học</th>
                      <th scope="col">Học phí</th>
                      <th scope="col">Hoàn thành (%)</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(course, index) in me.courses.data" :key="'course' + index">
                      <td class="align-middle">
                        <div class="d-lg-flex align-items-center">
                          <div class="thumbnail">
                            <router-link :to="{name: 'CourseDetail', params: {code: course.code}}">
                              <img :src="course.thumbnail.link" alt="" class="rounded"
                                   v-if="course.thumbnail && course.thumbnail.media_type === 'image'">
                            </router-link>
                          </div>
                          <div class="ms-lg-3 mt-2 mt-lg-0">
                            <h4 class="mb-1 text-primary-hover">
                              <router-link class="text-inherit" :to="{name: 'CourseDetail', params: {code: course.code}}">
                                {{ course.name }}
                              </router-link>
                            </h4>
                            <span class="fs-6"><span class="text-dark fw-medium">{{course.lesson_count}}</span> bài học</span>
                            <span class="ms-2 fs-6"><span class="text-dark fw-medium">0</span> học viên</span>
                          </div>
                        </div>
                      </td>
                      <td class="align-middle">
                        <span class="badge rounded-pill bg-success" v-if="course.fee_type === 0">Miễn phí</span>
                        <span class="badge rounded-pill bg-danger" v-if="course.fee_type === 1">Trả phí</span>
                        <span class="badge rounded-pill bg-primary" v-if="course.fee_type === 2">Theo combo</span>
                      </td>
                      <td class="align-middle">
                        <div class="progress" style="height: 6px;">
                          <div class="progress-bar bg-success" role="progressbar"
                               :style="course.userCoursePivot ? `width: ${course.userCoursePivot.percent}%` : `width: 0%`"
                               :aria-valuenow="course.userCoursePivot ? course.userCoursePivot.percent : 0" aria-valuemin="0"
                               aria-valuemax="100"></div>
                        </div>
                        <small>{{ course.userCoursePivot ? course.userCoursePivot.percent : 0 }}% hoàn thành</small>
                      </td>
                      <td class="align-middle text-muted align-middle border-top-0" v-if="role !== 'pupil'">
                            <span class="dropdown dropstart">
                              <a class="text-muted text-decoration-none" href="#" role="button" id="submitDropdown"
                                 data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>
                              <span v-if="isMe" class="dropdown-menu" aria-labelledby="submitDropdown" style="">
                                <span class="dropdown-header">Thao tác</span>
                                <a class="dropdown-item" v-on:click="setCurrentCourse(course)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#quitCourseModal"><i
                                    class="fe fe-x dropdown-item-icon"></i> Rời khóa học</a>
                              </span>
                              <span v-if="!isMe && (user.role === 'admin' || user.school_role === 'admin')" class="dropdown-menu" aria-labelledby="submitDropdown" style="">
                                <span class="dropdown-header">Thao tác</span>
                                <a class="dropdown-item" v-on:click="setCurrentCourse(course)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#removeUserCourseModal"><i
                                    class="fe fe-trash dropdown-item-icon"></i> Xóa khóa học</a>
                              </span>
                            </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <v-pagination
                      v-if="me.courses.paginatorInfo.lastPage > 1"
                      v-model="me.courses.paginatorInfo.currentPage"
                      :pages="me.courses.paginatorInfo.lastPage"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="loadMe"
                  />
                </div>
              </div>
            </div>
            <div :class="tab === 'submit' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-submit"
                 role="tabpanel" aria-labelledby="pills-submit-tab">
              <div class="col-md-12">
                <SubmitLesson v-on:dataChange="loadMe"
                              v-on:currentPageChange="currentPage => {me.submits.paginatorInfo.currentPage = currentPage; loadMe()}"
                              v-on:filterChange="filter => {submitFilter = filter; me.submits.paginatorInfo.currentPage = 1; loadMe()}"
                              :data="me.submits.data"
                              role="pupil"
                              :paginatorInfo="me.submits.paginatorInfo"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="changePasswordModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form v-on:submit.prevent="changePassword">
          <div class="modal-header">
            <h4 class="modal-title">
              Đổi mật khẩu
            </h4>
            <button type="button" id="changePasswordModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>

          <div class="modal-body">
            <label for="password" class="form-label">Nhập mật khẩu (*)</label>
            <input type="text" id="password" class="form-control" v-model="password" placeholder="tối thiểu 6 ký tự..." required>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-primary" type="submit">Đổi mật khẩu</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal fade" id="updateMeModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <form v-on:submit.prevent="updateMe">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              {{ updateMeModalTitle }}
            </h4>
            <button type="button" id="updateMeModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="mb-4 col-md-6">
                    <label for="addUserSchoolName" class="form-label">Họ tên (*)</label>
                    <input v-model="updateMeData.name" id="addUserSchoolName" class="form-control" type="text" required/>
                  </div>
                  <div class="mb-4 col-md-6" v-if="me.phone_active !== 1">
                    <label for="phone" class="form-label">Số ĐT (*)</label>
                    <input v-model="updateMeData.phone" id="phone" class="form-control" type="text"
                           required/>
                  </div>
                  <div class="mb-4 col-md-6" v-if="me.email_active !== 1">
                    <label for="email" class="form-label">Email (*)</label>
                    <input v-model="updateMeData.email" id="email" class="form-control" type="email"
                           required/>
                  </div>
                  <div class="mb-4 col-md-6">
                    <label class="form-label">Sinh nhật</label>
                    <Birthday v-model:dob="updateMeData.dob" v-model:mob="updateMeData.mob" v-model:yob="updateMeData.yob"/>
                  </div>
                  <div class="mb-4 col-md-6">
                    <label class="form-label">Giới tính</label>
                    <div class="">
                      <div class="d-inline-flex">
                        <div class="form-check me-3">
                          <input type="radio" id="sex0" v-model="updateMeData.sex" value=0
                                 class="form-check-input">
                          <label class="form-check-label" for="sex0">Nữ</label>
                        </div>
                        <div class="form-check me-3">
                          <input type="radio" id="sex1" v-model="updateMeData.sex" value=1
                                 class="form-check-input">
                          <label class="form-check-label" for="sex1">Nam</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Cập nhật</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" id="updateAvatarModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <form v-on:submit.prevent="updateAvatar" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Thay đổi avatar
            </h4>
            <button type="button" id="updateAvatarClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body text-center">
            <div>
              <img :src="updateMeData.thumbnail ? updateMeData.thumbnail.link : '/assets/images/avatar/avatar.jpg'" :alt="me.name"
                   class="avatar-xxl rounded-circle border border-4 border-white object-fit-cover">
            </div>
            <div class="w-100 justify-content-center d-flex mt-4">
              <Upload :message="updateAvatarMessage" v-on:uploadFinished="handleUploadFinished" class="justify-content-center d-table-cell align-items-center cursor-pointer text-center"/>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" id="removeUserCourseModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form v-on:submit.prevent="removeUserCourse">
          <div class="modal-header">
            <h4 class="modal-title">
              Xóa khóa học khỏi học viên
            </h4>
            <button type="button" id="removeUserCourseModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>

          <div class="modal-body">
            <p>Bạn có chắc chắn muốn xóa khóa học này của <span class="fw-bold">{{ me.name }}?</span></p>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-primary" type="submit">Xóa khóa học</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal fade" id="quitCourseModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form v-on:submit.prevent="quitCourse">
          <div class="modal-header">
            <h4 class="modal-title">
              Rời khóa học
            </h4>
            <button type="button" id="quitCourseModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <p>Bạn có chắc chắn muốn rời khóa học này?</p>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-primary" type="submit">Rời khóa học</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <Modal id="messageModal" :type="messageModalType" :message="messageModalMessage" :title="messageModalTitle"
         :message-detail="messageModalMessageDetail"/>
</template>

<script>
import Modal from "@/components/Modal";
import ApiService from "@/core/services/api.service";
import {getWhereCondition, openModal, closeModal, isMe, updateMe} from "@/core/services/utils.service";
import SubmitLesson from "@/components/SubmitLesson";
import Birthday from "@/components/Birthday";
import Upload from "@/components/Upload";
import {getUser} from "@/core/services/jwt.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "CourseStatistic",
  components: {
    Modal,
    Birthday,
    Upload,
    VPagination,
    SubmitLesson
  },
  data() {
    return {
      me: {
        name: "",
        email: "",
        role: "",
        email_active: 1,
        phone_active: 1,
        school_role: "",
        thumbnail: {
          link: ""
        },
        submits: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            lastPage: 0,
            total: 0
          }
        },
        courses: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            lastPage: 0,
            total: 0
          }
        }
      },
      messageModalType: "",
      messageModalMessage: "",
      messageModalTitle: "",
      messageModalMessageDetail: "",
      submitFilter: {
        lesson_name: "",
        is_reviewed: ""
      },
      tab: "course",
      password: "",
      updateMeData: {
        name: "",
        phone: "",
        thumbnail_id: null,
        dob: "",
        mob: "",
        yob: "",
        sex: "",
        thumbnail: {id: 0}
      },
      updateMeModalTitle : "Thay đổi thông tin cá nhân",
      updateAvatarMessage: "",
      courseFilter: {
        name: "",
        "user_course.percent": "",
        fee_type: ""
      },
      currentCourse: {
        name: ""
      },
      user: {
        uid: "",
        role: "",
        school_role: ""
      }
    }
  },
  methods: {
    loadMe() {
      let queryName = "";
      let queryNameParam = "";
      if (this.$route.params.uid) {
        queryName = "user";
        queryNameParam = `user(uid:"${this.$route.params.uid}")`;
      } else {
        queryName = 'me';
        queryNameParam = 'me';
      }

      let submitWhere = this.getSubmitWhereCondition();
      let courseWhere = this.getCourseWhereCondition();

      let query = `query {
        ${queryNameParam} {
          uid
          name
          role
          school_role
          email
          phone
          dob
          mob
          yob
          sex
          email_active
          phone_active
          thumbnail {
            link
          }
          submits(first: 5, page: ${this.me.submits.paginatorInfo.currentPage} ${submitWhere}){
            data {
              id
              lesson_name
              section_name
              is_reviewed
              comment
              created_at
              course {
                name
                code
              }
              reviewer {
                name
              }
              media {
                link
              }
              user {
                name
                uid
                thumbnail {
                  link
                }
              }
            }
            paginatorInfo {
              total
              lastPage
              currentPage
            }
          },
          courses(first: 5, page: ${this.me.courses.paginatorInfo.currentPage} ${courseWhere}){
            data {
              code
              name
              fee_type
              status
              section_count
              lesson_count
              created_at
              thumbnail {
                id
                link
                media_type
              }
              userCoursePivot {
                percent
              }
            }
            paginatorInfo {
              total
              lastPage
              currentPage
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data[queryName]) {
              this.me = data.data[queryName];
              this.updateMeData.name = this.me.name;
              this.updateMeData.phone = this.me.phone;
              this.updateMeData.dob = this.me.dob;
              this.updateMeData.mob = this.me.mob;
              this.updateMeData.yob = this.me.yob;
              this.updateMeData.sex = this.me.sex;
              this.updateMeData.thumbnail = this.me.thumbnail;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    getSubmitWhereCondition() {
      let filters = {};

      for (let key in this.submitFilter) {
        if (key === "name" || key === "email" || key === "phone" || key === "lesson_name") {
          if (this.submitFilter[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.submitFilter[key] + "%"
            }
          }
        } else if (key === "is_reviewed") {
          if (this.submitFilter[key] !== "") {
            filters[key] = {
              operator: "EQ",
              value: this.submitFilter[key]
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    setTab(tab) {
      this.tab = tab;
    },
    changePassword() {
      this.messageModalTitle = "Đổi mật khẩu";

      let query = `mutation($password: String!, $uid: String) {
        changePassword(password: $password, uid: $uid)
      }`;

      let uid = this.$route.params.uid ? this.$route.params.uid : null;

      ApiService.graphql(query, {password: this.password, uid: uid})
          .then(({data}) => {
            if (data.data && data.data.changePassword) {
              this.messageModalMessage = "Đổi mật khẩu thành công";
              this.messageModalType = "success";
              closeModal("changePasswordModal");
              openModal("messageModal");
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("messageModal");
          });
    },
    updateMe() {
      this.messageModalTitle = "Cập nhật thông tin cá nhân";

      let query = `mutation($name: String, $email: String, $phone: String, $thumbnail_id: Int, $dob: Int, $mob: Int, $yob: Int, $sex: Int) {
        updateMe(name: $name, email: $email, phone: $phone, thumbnail_id: $thumbnail_id, dob: $dob, mob: $mob, yob: $yob, sex: $sex) {
          uid
        }
      }`;

      this.updateMeData.dob = parseInt(this.updateMeData.dob);
      this.updateMeData.mob = parseInt(this.updateMeData.mob);
      this.updateMeData.yob = parseInt(this.updateMeData.yob);
      this.updateMeData.sex = parseInt(this.updateMeData.sex);

      ApiService.graphql(query, this.updateMeData)
          .then(({data}) => {
            if (data.data && data.data.updateMe) {
              this.messageModalMessage = "Cập nhật thông tin thành công";
              this.messageModalType = "success";
              closeModal("updateMeModal");
              openModal("messageModal");
              this.loadMe();
              updateMe("updateMe");
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("messageModal");
          });
    },
    updateAvatar() {
      let query = `mutation($thumbnail_id: Int) {
        updateMe(thumbnail_id: $thumbnail_id) {
          uid
        }
      }`;

      ApiService.graphql(query, {thumbnail_id: parseInt(this.updateMeData.thumbnail.id)})
          .then(({data}) => {
            if (data.data && data.data.updateMe) {
              this.updateAvatarMessage = "Thay đổi avatar thành công";
              this.$emit("updateMe");
              this.loadMe();
              updateMe("updateAvatar");
            } else {
              this.updateAvatarMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.updateAvatarMessage = response.message;
          });
    },
    handleUploadFinished(media) {
      if (typeof media === "object") {
        this.updateMeData.thumbnail = media;
        this.updateAvatar();
      }
    },
    getCourseWhereCondition() {
      let filters = {};

      for (let key in this.courseFilter) {
        if (key === "name") {
          if (this.courseFilter[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.courseFilter[key] + "%"
            }
          }
        } else if (key === "user_course.percent") {
          if (this.courseFilter[key] !== "") {
            if (this.courseFilter[key] === "0") {
              filters[key] = {
                operator: "LT",
                value: 100
              }
            } else {
              filters[key] = {
                operator: "EQ",
                value: this.courseFilter[key]
              }
            }
          }
        } else {
          if (this.courseFilter[key] !== "") {
            filters[key] = {
              operator: "EQ",
              value: this.courseFilter[key]
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    setCurrentCourse(course) {
      this.currentCourse = course;
    },
    removeUserCourse() {
      this.messageModalTitle = "Xóa khóa học khỏi học viên";

      let query = `mutation($uid: String!, $course_code: String!) {
        removeUserCourse(uid: $uid, course_code: $course_code)
      }`;

      ApiService.graphql(query, {uid: this.me.uid, course_code: this.currentCourse.code})
          .then(({data}) => {
            if (data.data && data.data.removeUserCourse) {
              this.messageModalMessage = "Xóa khóa học khỏi học viên thành công";
              this.messageModalType = "success";
              closeModal("removeUserCourseModal");
              openModal("messageModal");
              this.loadMe();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              closeModal("removeUserCourseModal");
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            closeModal("removeUserCourseModal");
            openModal("messageModal");
          });
    },
    quitCourse() {
      this.messageModalTitle = "Rời khóa học";

      let query = `mutation($course_code: String!) {
        quitCourse(course_code: $course_code)
      }`;

      ApiService.graphql(query, {course_code: this.currentCourse.code})
          .then(({data}) => {
            if (data.data && data.data.quitCourse) {
              this.messageModalMessage = "Rời khóa học thành công";
              this.messageModalType = "success";
              closeModal("quitCourseModal");
              openModal("messageModal");
              this.loadMe();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              closeModal("quitCourseModal");
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            closeModal("quitCourseModal");
            openModal("messageModal");
          });
    }
  },
  mounted() {
    this.loadMe();
    this.user = getUser();

    let updateMissingInfo = this.$route.query.updateMissingInfo;

    console.log("updateMissingInfo", updateMissingInfo);

    if (updateMissingInfo === "yes") {
      this.setTab("info");
      this.updateMeModalTitle = "Vui lòng cập nhật thông tin cá nhân còn thiếu";
      openModal("updateMeModal");
    }
  },
  computed: {
    isMe() {
      return isMe(this.me);
    }
  },
  watch: {
    courseFilter: {
      handler() {
        this.loadMe();
      },
      deep: true
    },
    submitFilter: {
      handler() {
        this.loadMe();
      },
      deep: true
    }
  }
}
</script>
